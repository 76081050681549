// ICONS
export { default as ArrowRight } from './images/icons/ArrowRight';
export { default as Cross } from './images/icons/Cross';
export { default as Hamburger } from './images/icons/Hamburger';
export { default as Linkedin } from './images/icons/Linkedin';
export { default as X } from './images/icons/X';
export { default as Plus } from './images/icons/Plus';
export { default as Content } from './images/icons/Content';
export { default as Data } from './images/icons/Data';
export { default as Expertise } from './images/icons/Expertise';
export { default as ChevronDown } from './images/icons/ChevronDown';

// BACKGROUNDS
export { default as FeaturesBackgroundRight } from './images/backgrounds/features-backgound-right.svg';
export { default as FeaturesBackgroundLeft } from './images/backgrounds/features-background-left.svg';
export { default as HeroBackgroundMobile } from './images/backgrounds/hero-background-gradient-mobile.svg';
export { default as HeroBackgroundGradientXL } from './images/backgrounds/hero-background-gradient-xl.svg';
export { default as HeroBackgroundGradientDesktop } from './images/backgrounds/hero-backgrund-gradient-desktop.svg';
export { default as HeroBackgroundMisc1 } from './images/backgrounds/hero-misc-1.svg';
export { default as HeroBackgroundMisc2 } from './images/backgrounds/hero-misc-2.svg';
export { default as HeroBackgroundMiscMobile } from './images/backgrounds/hero-misc-mobile.svg';
export { default as HeroBackgroundCoins1 } from './images/backgrounds/hero-coins-1.svg';
export { default as HeroBackgroundCoins2 } from './images/backgrounds/hero-coins-2.svg';
export { default as HeroBackgroundCoinsMobile } from './images/backgrounds/hero-coins-mobile.svg';
export { default as LobbyBackground } from './images/backgrounds/lobby-background.svg';
export { default as QuotesBackground } from './images/backgrounds/quotes-background.svg';
export { default as TeamBackground } from './images/backgrounds/team-background.svg';
export { default as TeamGrid } from './images/backgrounds/team-grid.svg';
export { default as PartnersBackground } from './images/backgrounds/partners-background.svg';

// NAVBAR
export { default as NavbarSticker } from './images/navbar-sticker.svg';
export { default as NavbarPrize } from './images/navbar-prize.svg';
export { default as NavbarScore } from './images/navbar-score.svg';

// LOBBY
export { default as TrophyBadge } from './images/trophy-badge.png';
export { default as StarBadge } from './images/star-badge.png';
export { default as BrandNewBadge } from './images/brand-new-badge.svg';
export { default as CursorBadge } from './images/cursor-badge.png';
export { default as LobbyPhone } from './images/lobby-phone.webp';
export { default as LobbyPhone2 } from './images/lobby-phone-2.webp';

// FEATURES
export { default as FeaturePhone1 } from './images/features-phone-1.webp';
export { default as FeaturePhone2Dark } from './images/features-phone-2-dark.webp';
export { default as FeaturePhone2Light } from './images/features-phone-2-light.webp';
export { default as FeaturePhone3 } from './images/features-phone-3.webp';
export { default as FeaturePhone4 } from './images/features-phone-4.webp';
export { default as MedalBadge } from './images/medal-badge.svg';
export { default as ColorPickerBadge } from './images/color-picker-badge.svg';
export { default as DollarBadge } from './images/dollar-badge.svg';
export { default as EuroBadge } from './images/euro-badge.svg';
export { default as LanguageBadge } from './images/language-badge.svg';
export { default as ESL } from './images/ESL.webp';
export { default as G2 } from './images/G2.png';
export { default as Tennis } from './images/tennis.png';
export { default as AmericanFootball } from './images/american-football.png';
export { default as InsertLogoText } from './images/insert-logo-text.webp';
export { default as InsertLogoIcon } from './images/insert-logo-icon.webp';
export { default as ColorWheel } from './images/color-wheel.webp';
export { default as ArrowCursor } from './images/arrow-cursor.svg';
export { default as Prize10000 } from './images/prize-10000.webp';
export { default as Banner } from './images/banner.webp';
export { default as LanguageDropdown } from './images/language-dropdown.webp';
export { default as CountryFlags } from './images/country-flags.webp';

// LOGOS
export { default as AltLogo } from './images/logos/alt-logo.svg';
export { default as MainLogo } from './images/logos/main-logo.svg';

export { default as Altenar } from './images/logos/altenar.png';
export { default as AppsFlyer } from './images/logos/appsFlyer.png';
export { default as Arland } from './images/logos/arland.png';
export { default as BtoBet } from './images/logos/btoBet.png';
export { default as CaptainUp } from './images/logos/captainUp.png';
export { default as Fastrack } from './images/logos/fastrack.png';
export { default as Firebase } from './images/logos/firebase.png';
export { default as Jumpman } from './images/logos/jumpman.png';
export { default as Kambi } from './images/logos/kambi.png';
export { default as OpenBet } from './images/logos/openBet.png';
export { default as Optimove } from './images/logos/optimove.png';
export { default as Salesforce } from './images/logos/salesforce.png';
export { default as Twilio } from './images/logos/twilio.png';
export { default as XtremePush } from './images/logos/xtremepush.png';
export { default as SwitchAnalytics } from './images/logos/switchAnalytics.png';
export { default as Bragg } from './images/logos/bragg.png';
export { default as Gig } from './images/logos/gig.png';

export { default as Jacks } from './images/logos/jacks.svg';
export { default as JacksTestimonial } from './images/logos/jacks-testimonial.svg';
export { default as TenBet } from './images/logos/10Bet.svg';
export { default as Sport888 } from './images/logos/888sport.svg';
export { default as AmazonSlots } from './images/logos/amazonSlots.svg';
export { default as B2Tech } from './images/logos/b2Tech.svg';
export { default as Bwin } from './images/logos/bwin.svg';
export { default as ComeOn } from './images/logos/comeOn.svg';
export { default as CrabSports } from './images/logos/crabSports.svg';
export { default as PrideBet } from './images/logos/prideBet.svg';
export { default as PrideBetTestimonial } from './images/logos/prideBet-testimonial.svg';
export { default as GOAT } from './images/logos/GOAT.svg';
export { default as JoiGaming } from './images/logos/joiGaming.svg';
export { default as Lucky7Ventures } from './images/logos/lucky7Ventures.svg';
export { default as PremierBet } from './images/logos/premierBet.svg';
export { default as Rhino } from './images/logos/rhino.svg';
export { default as RooBet } from './images/logos/rooBet.svg';
export { default as SmallScreenCasinos } from './images/logos/smallScreenCasinos.png';

// FLAGS
export { default as England } from './images/flags/england.svg';
export { default as Finland } from './images/flags/finland.svg';
export { default as France } from './images/flags/france.svg';
export { default as Germany } from './images/flags/germany.svg';
export { default as India } from './images/flags/india.svg';
export { default as Italy } from './images/flags/italy.svg';
export { default as Japan } from './images/flags/japan.svg';
export { default as Kenya } from './images/flags/kenya.svg';
export { default as Netherlands } from './images/flags/netherlands.svg';
export { default as Norway } from './images/flags/norway.svg';
export { default as Poland } from './images/flags/poland.svg';
export { default as Portugal } from './images/flags/portugal.svg';
export { default as SaudiArabia } from './images/flags/saudi-arabia.svg';
export { default as Spain } from './images/flags/spain.svg';
export { default as Ukraine } from './images/flags/ukraine.svg';
export { default as USA } from './images/flags/usa.svg';

// TEAM
export { default as Jose } from './images/jose.webp';
export { default as Adam } from './images/adam.webp';
export { default as Arthur } from './images/arthur.webp';
export { default as Marc } from './images/marc.webp';
export { default as Marko } from './images/marko.webp';
export { default as Oliver } from './images/oliver.webp';
export { default as Ricky } from './images/ricky.webp';
export { default as Ben } from './images/ben.webp';
export { default as Chris } from './images/chris.webp';
export { default as Yannis } from './images/yannis.webp';
export { default as Iryna } from './images/iryna.webp';
export { default as Bonham } from './images/bonham.webp';

// FOOTER
export { default as Sticker1 } from './images/stickers/sticker-1.svg';
export { default as Sticker2 } from './images/stickers/sticker-2.svg';
export { default as Sticker3 } from './images/stickers/sticker-3.svg';
export { default as Sticker4 } from './images/stickers/sticker-4.svg';
export { default as Sticker5 } from './images/stickers/sticker-5.svg';
export { default as Sticker6 } from './images/stickers/sticker-6.svg';
export { default as Sticker7 } from './images/stickers/sticker-7.svg';
export { default as Sticker8 } from './images/stickers/sticker-8.svg';

// GAME
export { default as SportsStep1 } from './images/game/sports-step1.webp';
export { default as SportsStep2 } from './images/game/sports-step2.webp';
export { default as SportsStep3 } from './images/game/sports-step3.webp';
export { default as SportsStep4 } from './images/game/sports-step4.webp';
export { default as CasinoStep1 } from './images/game/casino-step1.webp';
export { default as CasinoStep2 } from './images/game/casino-step2.webp';
export { default as CasinoStep3 } from './images/game/casino-step3.webp';
export { default as GameLazyLoad } from './images/game/lazy-load.webp';
